.eclipse .dark .sidebar, .eclipse .dark .sidebar .logo{
   @apply bg-sidebar-bg
}

.no-animation {
   @apply transition-none !important
}
.sidebar{
   @apply transition-all duration-300 ease-in-out
}
.sidebar.full{
   @apply w-72
}
.sidebar.collapsed{
   @apply w-20
}

.sidebar .collapse-btn{
   @apply transition-all duration-500 ease-in-out
}
.sidebar.collapsed .collapse-btn{
   @apply right-1 rotate-180
}

.sidebar nav a{
   @apply transition-all duration-300 ease-in-out
}
.sidebar.full nav a{
   @apply w-full
}
.sidebar.collapsed nav a{
   @apply px-2 items-center w-10 
}


.sidebar.full nav a{
   @apply mx-auto transition-all duration-100 ease-in-out
}
.sidebar.collapsed nav a{
   @apply mx-auto transition-all duration-700 ease-in-out
}

.sidebar nav a .menu-item, .sidebar nav a .icon{
   @apply whitespace-nowrap transition-all duration-300 ease-in-out
}
.sidebar nav a .menu-item span, .sidebar nav a .icon svg{
   @apply transition-all duration-300 ease-in-out
}
.sidebar.full nav a .menu-item{
   @apply inline-flex
}
.sidebar.collapsed nav a .menu-item{
   @apply hidden
}
.sidebar.full nav a .menu-item span{
   @apply opacity-100 
}
.sidebar.collapsed nav a .menu-item span{
   @apply opacity-0
}

.sidebar nav a .dot{
   @apply whitespace-nowrap transition-all duration-500 ease-in-out
}
.sidebar.full nav a .dot{
   @apply hidden
}
.sidebar.collapsed nav a .dot{
   @apply inline-flex
}

.sidebar nav .separator{
   @apply transition-all duration-300 delay-500 ease-in-out
}
.sidebar.full nav .separator{
   @apply hidden
}
.sidebar.collapsed nav .separator{
   @apply inline-flex
}

.sidebar nav .title{
   @apply transition-all duration-300 delay-500 ease-in-out
}
.sidebar.full nav .title{
   @apply inline-flex
}
.sidebar.collapsed nav .title{
   @apply hidden 
}

.sidebar .user-info{
   @apply transition-all duration-300 delay-500 ease-in-out truncate
}
.sidebar .user-info a{
   @apply truncate
}
.sidebar.full .user-info{
   @apply inline-flex
}
.sidebar.collapsed .user-info{
   @apply hidden
}

.sidebar.collapsed .avatar{
   @apply ml-1
}
.sidebar.full .avatar div{
   @apply invisible
}
.sidebar.collapsed .avatar div{
   @apply visible
}

.sidebar nav .icon{
   @apply text-gray-400 hover:text-gray-800
}
.sidebar nav .active .icon{
   @apply text-primary hover:text-primary dark:text-primary-light dark:hover:text-primary-light group-hover:text-gray-800 dark:group-hover:text-gray-300
}
.sidebar nav .active:hover .icon{
   @apply text-primary dark:text-primary-light
}
.sidebar.collapsed nav .active .icon{
   @apply text-primary dark:text-primary-light
}

.sidebar.full .popover-content{
   @apply invisible
}
.sidebar.full .popover-content.popover-doh{
   @apply visible ml-[268px]
}
.sidebar nav .active .popover-content{
   @apply text-primary dark:text-primary-light
}

main.sidebar-collapsed{
   @apply lg:pl-20 transition-all duration-300 ease-in-out 
}
main.sidebar-full{
   @apply lg:pl-72 transition-all duration-300 ease-in-out
}
