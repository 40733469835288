.show-more-list li{
  @apply mr-1 hidden
}
.show-more-list li:nth-child(2):not(.show){
  @apply mr-0
}
.show-more-list li.show {
  @apply inline-flex
}
.show-more-list li:nth-child(-n + 2){
  @apply inline-flex
}
.show-more-list li.show:nth-child(2)::after{
  content: ", ";
}
.show-more-list.labels-list li.show:nth-child(2)::after {
  content: "";
}
.show-more-list.labels-list li {
  @apply -mr-4;
}
