/*
 * Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
 * the trix-editor content (whether displayed or under editing). Feel free to incorporate this
 * inclusion directly in any other asset bundle and remove this file.
 *
 *= require trix
*/

@import "trix";

/*
 * We need to override trix.css’s image gallery styles to accommodate the
 * <action-text-attachment> element we wrap around attachments. Otherwise,
 * images in galleries will be squished by the max-width: 33%; rule.
*/

.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment, .trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

.trix-content ul {
  list-style-type: disc;
}

.trix-content ol {
  list-style-type: decimal;
}

trix-editor{
  @apply bg-white dark:bg-gray-800 disabled:bg-gray-100 dark:disabled:bg-gray-700 rounded-md w-full py-2.5 px-3 border-0 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:ring-2 focus:ring-primary dark:focus:ring-primary-dark focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary dark:focus-visible:ring-primary-dark text-sm font-normal text-gray-800 dark:text-gray-200 placeholder:text-gray-400
}
trix-editor:focus{
  @apply ring-2 ring-primary dark:ring-primary-dark
}
trix-editor:focus-visible{
  @apply ring-2 ring-inset ring-primary dark:ring-primary-dark outline-none
}
trix-toolbar .trix-button-group{
  @apply border-gray-300 dark:border-gray-700 rounded-md shadow-none overflow-hidden
}
trix-toolbar .trix-button{
  @apply text-gray-500 dark:text-gray-400 border-0 px-4 py-2.5 bg-gray-50 dark:bg-gray-100/50
}
trix-toolbar .trix-button--icon{
  @apply h-7
}
trix-toolbar .trix-button--icon::before{
  @apply top-1 bottom-1
}
trix-toolbar .trix-button:not(:first-child){
  @apply border-gray-300 dark:border-gray-700
}
trix-toolbar .trix-button.trix-active{
  @apply bg-gray-100 dark:bg-gray-700
}
trix-toolbar .trix-dialog{
  @apply mt-1 ml-1 border border-gray-300 dark:border-gray-700 rounded-md shadow-none bg-white dark:bg-gray-800
}
trix-toolbar .trix-dialog .trix-button{
  @apply bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200
}
trix-toolbar .trix-input--dialog{
  @apply bg-white dark:bg-gray-800 disabled:bg-gray-100 dark:disabled:bg-gray-700 rounded-md w-full py-2.5 px-3 border-0 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:ring-2 focus:ring-primary dark:focus:ring-primary-dark focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary dark:focus-visible:ring-primary-dark text-sm font-normal text-gray-800 dark:text-gray-200 placeholder:text-gray-400
}
trix-toolbar .trix-input:focus{
  @apply ring-2 ring-inset ring-primary dark:ring-primary-dark outline-none
}
.trix-content pre{
  @apply bg-gray-50 dark:bg-gray-900 whitespace-pre-line rounded-lg p-4
}
.trix-content a{
  @apply font-medium text-primary dark:text-primary-dark
}
