.sidebar .logo-full{
  @apply inline-flex
}
.sidebar .logo-small{
  @apply hidden
}
.sidebar.collapsed .logo-full{
  @apply hidden
}
.sidebar.collapsed .logo-small{
  @apply inline-flex
}
