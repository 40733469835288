/* buttons */
.btn{
   @apply items-center justify-center lg:space-x-2 text-sm font-medium border rounded-full focus:outline-2 focus:outline-offset-2 focus:outline-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary cursor-pointer transition-all duration-300 ease-in-out leading-6 disabled:opacity-50 disabled:cursor-not-allowed
}
.btn-xs{
   @apply text-xs
}
.btn svg{
   @apply shrink-0
}
.btn-primary{
   @apply text-white bg-primary dark:bg-primary-dark hover:bg-primary-hover border-transparent
}
.btn-red{
   @apply text-white bg-red-400 dark:bg-red-400 hover:bg-red-300 border-transparent
}
.btn-soft{
   @apply text-primary dark:text-primary-dark bg-gray-100 dark:bg-gray-700 border-transparent hover:border-primary dark:hover:border-primary-dark
}
.btn-soft-b{
   @apply text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border-transparent hover:border-gray-200 dark:hover:border-gray-600
}
.btn-outline{
   @apply text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-700 hover:shadow-light-hover dark:shadow-dark dark:hover:shadow-dark-hover
}
.btn-outline-w{
   @apply text-white border-gray-200 hover:border-gray-100
}
.btn-outline.disabled{
   @apply text-gray-400 dark:text-gray-400 pointer-events-none
}
.btn-ghost{
   @apply border-0 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 hover:bg-black/5 dark:hover:bg-gray-700
}
.btn-ghost-w{
   @apply border-0 text-white bg-transparent hover:bg-white/10
}
.btn-ghost-b{
   @apply border-0 text-gray-800 hover:text-gray-700 dark:text-gray-200 hover:dark:text-gray-300 bg-transparent hover:bg-transparent
}
.btn-ghost-primary{
   @apply border-0 text-primary dark:text-primary-dark hover:text-primary-hover dark:hover:text-primary-hover hover:bg-primary-hover dark:hover:bg-primary-hover
}
.btn-icon-only{
   @apply space-x-0
}

/* dropdown */
.dropdown{
   @apply overflow-hidden bg-white border border-gray-200 rounded-lg dark:bg-gray-800 shadow-light dark:shadow-dark dark:hover:shadow-dark-hover dark:border-gray-700
}
.dropdown-link{
   @apply w-full flex text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 py-1.5 px-2 space-x-4 rounded-md cursor-pointer
}
.dropdown-link.selected{
   @apply bg-gray-100 dark:bg-gray-700
}
.dropdown-link svg{
   @apply text-gray-400
}
.dropdown-link.selected svg{
   @apply text-gray-800 dark:text-gray-200
}
.dropdown-link span{
   @apply text-gray-800 dark:text-gray-200
}
.dropdown-link.hidden{
   display: none !important;
}

.sort_link.selected{
   @apply inline;
}

/* abbr tag */
abbr:where([title]){
   @apply no-underline text-red-400
}

/* Hide arrows on input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* scrollbars */
/* Hide scrollbar for Chrome, Safari and Opera */
.invisible-scrollbar::-webkit-scrollbar {
   display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.invisible-scrollbar {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}

.contenteditable:empty:before {
  @apply text-gray-400;
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}

/* table views */
.table-views-wrapper{
   @apply max-w-[calc(100vw-44px)] lg:max-w-[calc(100vw-60px)]
}
.sidebar-collapsed .table-views-wrapper{
   @apply lg:max-w-[calc(100vw-160px)]
}
.sidebar-collapsed .table-views-wrapper.table-views-wrapper-sub{
   @apply lg:max-w-[calc(100vw-424px)]
}
.sidebar-full .table-views-wrapper{
   @apply lg:max-w-[calc(100vw-368px)]
}
.sidebar-full .table-views-wrapper.table-views-wrapper-sub{
   @apply lg:max-w-[calc(100vw-632px)]
}
.table-views {
   @apply border-separate border-spacing-0 border-gray-200 dark:border-gray-700
}
.table-views thead th {
   @apply border-r border-gray-200 dark:border-gray-700
}
.table-views tbody td {
   @apply border-t border-r border-gray-200 dark:border-gray-700
}
.table-views thead th:first-child,
.table-views tbody td:first-child{
   @apply border-l-0 border-gray-200 dark:border-gray-700 lg:sticky left-0 z-10 lg:bg-white lg:dark:bg-gray-800
}
.table-views thead th:last-child,
.table-views tbody td:last-child {
   @apply border-l border-r-0 border-gray-200 dark:border-gray-700 lg:sticky right-0 lg:bg-white lg:dark:bg-gray-800 z-20
}
.table-views tbody tr:hover td:first-child,
.table-views tbody tr:hover td:last-child{
   @apply bg-gray-50 dark:bg-gray-900
}
.table-views thead th:nth-last-child(2),
.table-views tbody td:nth-last-child(2) {
   @apply border-r-0
}
.table-views tbody tr:only-child td {
   @apply border-l-0
}
.table-views tfoot td{
   @apply h-12 pb-14
}
.table-views tfoot td > div{
   @apply absolute w-[calc(100%-120px)]
}

.select-country select:not([size]) {
   @apply pr-7
}

/* Tabs with scrollbar */
.page-views-tabs-scroll{
   @apply overflow-x-auto overflow-y-visible
}
.sidebar-collapsed .page-views-tabs-scroll{
   @apply lg:max-w-[calc(100vw-95px)]
}
.sidebar-full .page-views-tabs-scroll{
   @apply lg:max-w-[calc(100vw-304px)]
}
.tabs-scroll{
   @apply overflow-x-auto overflow-y-visible
}
.sidebar-collapsed .tabs-scroll{
   @apply lg:max-w-[calc(100vw-384px)]
}
.sidebar-full .tabs-scroll{
   @apply lg:max-w-[calc(100vw-592px)]
}

/* Settings menu */
.settings-menu .icon svg{
   @apply text-primary dark:text-primary-dark
}
.settings-menu .icon:hover svg{
   @apply text-primary-hover dark:text-primary-hover
}

/* Flash messages */
.flash{
   @apply bg-white border-l-[3px] border-primary dark:border-primary-dark dark:bg-gray-800 text-gray-800
}
.flash.flash-alert{
   @apply border-red-400 dark:border-red-400 bg-red-50 dark:bg-red-50 text-red-900
}
.flash.flash-notice{
   @apply border-green-500 dark:border-green-500 bg-green-100 dark:bg-green-100 text-lime-900
}
.flash.flash-warning{
   @apply border-amber-400 dark:border-amber-400 bg-yellow-50 dark:bg-yellow-50 text-amber-900
}

/* Image preview */
.image-preview-wrapper img[hidden]{
   @apply hidden
}

/* Questionnaire table */
.questionnaire-table-cell {
   width: 100%;
   @apply border-t border-l border-gray-300 dark:border-gray-600
}

.questionnaire-table-cell:last-child {
   @apply border-r
}

tr:last-child .questionnaire-table-cell {
   @apply border-b
}

.questionnaire-table-cell {
    @apply outline-none
}

/* Sort link */
.is-selected .sort_link:hover svg{
   @apply rotate-180
}

trix-toolbar .trix-button-row{
   @apply flex-wrap md:flex-nowrap !important
}
