.flatpickr-calendar {
   @apply mt-2 rounded-lg bg-white dark:bg-gray-800 p-4 w-fit animate-none ring-1 ring-gray-200 shadow-light-hover dark:shadow-dark-hover dark:ring-gray-700
}
.flatpickr-calendar::before, .flatpickr-calendar::after{
   @apply border-0
}

.scheduled_workflow_perform_at label{
   @apply mb-1.5
}
.flatpickr-input {
   @apply bg-no-repeat cursor-pointer bg-[length:20px_20px] bg-[center_right_16px] min-w-[25%] pr-10;
   background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZT0iIzljYTNhZiIgY2xhc3M9InctNiBoLTYiPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTYuNzUgM3YyLjI1TTE3LjI1IDN2Mi4yNU0zIDE4Ljc1VjcuNWEyLjI1IDIuMjUgMCAwMTIuMjUtMi4yNWgxMy41QTIuMjUgMi4yNSAwIDAxMjEgNy41djExLjI1bS0xOCAwQTIuMjUgMi4yNSAwIDAwNS4yNSAyMWgxMy41QTIuMjUgMi4yNSAwIDAwMjEgMTguNzVtLTE4IDB2LTcuNUEyLjI1IDIuMjUgMCAwMTUuMjUgOWgxMy41QTIuMjUgMi4yNSAwIDAxMjEgMTEuMjV2Ny41bS05LTZoLjAwOHYuMDA4SDEydi0uMDA4ek0xMiAxNWguMDA4di4wMDhIMTJWMTV6bTAgMi4yNWguMDA4di4wMDhIMTJ2LS4wMDh6TTkuNzUgMTVoLjAwOHYuMDA4SDkuNzVWMTV6bTAgMi4yNWguMDA4di4wMDhIOS43NXYtLjAwOHpNNy41IDE1aC4wMDh2LjAwOEg3LjVWMTV6bTAgMi4yNWguMDA4di4wMDhINy41di0uMDA4em02Ljc1LTQuNWguMDA4di4wMDhoLS4wMDh2LS4wMDh6bTAgMi4yNWguMDA4di4wMDhoLS4wMDhWMTV6bTAgMi4yNWguMDA4di4wMDhoLS4wMDh2LS4wMDh6bTIuMjUtNC41aC4wMDh2LjAwOEgxNi41di0uMDA4em0wIDIuMjVoLjAwOHYuMDA4SDE2LjVWMTV6IiAvPgo8L3N2Zz4K');
}

.flatpickr-day{
   @apply items-center w-10 h-10 font-semibold text-gray-800 dark:text-gray-200 border border-transparent hover:border-transparent rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300
}
.flatpickr-day.today{
   @apply border-gray-300 dark:border-gray-700
}
.flatpickr-day.selected{
   @apply bg-primary dark:bg-primary-dark text-white dark:text-white border-primary hover:border-primary-dark
}
.flatpickr-day.nextMonthDay, .flatpickr-day.prevMonthDay{
   @apply font-normal text-gray-400 dark:text-gray-500
}
.flatpickr-day.flatpickr-disabled{
   @apply text-gray-400 dark:text-gray-400
}

.flatpickr-weekdays{
   @apply mb-2
}
span.flatpickr-weekday{
   @apply text-sm font-normal text-gray-500 dark:text-gray-400
}

.flatpickr-months{
   @apply flex items-center relative mb-2
}
.flatpickr-current-month{
   @apply flex p-0 justify-center items-center
}
.flatpickr-current-month span.cur-month{
   @apply cursor-default bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 mr-2 text-base font-medium
}
.flatpickr-current-month span.cur-month:hover{
   @apply bg-white dark:bg-gray-800
}
.flatpickr-current-month input.cur-year{
   @apply cursor-pointer bg-none text-gray-800 dark:text-gray-200 text-base font-medium focus:ring-2 focus:ring-primary dark:focus:ring-primary-dark rounded-md overflow-hidden
}
.flatpickr-current-month input.cur-year:hover{
   @apply bg-gray-50 dark:bg-gray-700
}
.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month{
   @apply rounded-full p-2
}
.flatpickr-next-month svg, .flatpickr-prev-month svg {
   @apply w-4 h-4 fill-primary dark:fill-primary-dark
}
.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg{
   @apply fill-primary-hover dark:fill-primary-hover
}

.flatpickr-current-month .numInputWrapper span.arrowUp, .flatpickr-current-month .numInputWrapper span.arrowDown{
   @apply text-gray-800 dark:text-gray-200
}
.flatpickr-current-month .numInputWrapper span.arrowUp::after{
   @apply border-b-gray-800 dark:border-b-gray-200
}
.flatpickr-current-month .numInputWrapper span.arrowDown::after{
   @apply border-t-gray-800 dark:border-t-gray-200
}
.flatpickr-wrapper {
   @apply block w-full
}
/*


.flatpickr-current-month {
   @apply flex p-0 justify-center items-center
}

.flatpickr-current-month span.cur-month {
   @apply cursor-default bg-none text-black dark:text-white mr-3 text-lg font-bold
}

.flatpickr-current-month span.cur-month:hover {
   @apply cursor-default bg-transparent font-semibold
}

.flatpickr-current-month input.cur-year {
   @apply cursor-pointer bg-none text-black dark:text-white font-bold
}

.flatpickr-monthDropdown-months {
   @apply font-extrabold text-black dark:text-white
}

.dayContainer {
   @apply gap-0
}



.prevMonthDay {
   @apply text-gray-400 dark:text-gray-500
}

.flatpickr-day:hover {
   @apply bg-gray-100 dark:bg-gray-600
}

.flatpickr-day.selected {
   @apply bg-primary-hover rounded-full dark:bg-primary-hover border-0
}

.flatpickr-weekday {
   @apply text-sm font-bold text-gray-500 dark:text-gray-400
}

.numInputWrapper {
   @apply text-lg font-semibold text-gray-500 dark:text-gray-400
}

.datepicker-dropdown {
   @apply mt-1 z-[9999]
}

.datepicker-input.datepicker-range-input{
   @apply max-w-[150px]
}*/
