.nst-container{
   @apply absolute
}
.nst-list li{
   @apply list-none
}
.nst-item{
   @apply relative
}
.nst-dragging .nst-content{
   @apply rounded-md border border-dashed border-gray-300 dark:border-gray-600
}
.nst-item .nst-content{
   @apply p-0 overflow-hidden text-ellipsis whitespace-nowrap flex items-center justify-between
}
.nst-item.nst-error > .nst-content{
   @apply border-red-400 bg-red-400/10
}
.nst-item .nst-button{
   @apply absolute w-5 h-5 top-1 bg-transparent border-0 p-0 left-2 text-base flex items-center justify-center cursor-pointer
}
.nst-item.nst-collapsed .nst-list{
   @apply hidden
}
.nst-error.nst-placeholder{
   @apply relative border-red-400 bg-red-400/10
}
.nst-active > .nst-content{
   @apply bg-gray-100
}
.nst-handle{
   @apply cursor-move
}
.nst-placeholder{
   @apply box-border rounded-md mb-1 border border-dashed border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-900
}
.nst-list{
   @apply box-border p-0 m-0
}
li > .nst-list, li > .nst-list{
   @apply pl-5 box-border
}
