.ts-control{
  @apply rounded-md w-full py-2.5 px-3 border-0 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 overflow-x-hidden
}
.ring-transparent .ts-control{
  @apply ring-0 dark:ring-0 rounded-none rounded-l-md flex-nowrap
}
.ring-transparent.rounded-focus .ts-control{
  @apply rounded-md
}
.changed .ts-control, .focus .ts-control{
  @apply ring-2 ring-inset ring-primary dark:ring-primary-dark bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400
}
.disabled .ts-control{
  @apply bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 opacity-100
}
.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active{
   @apply border border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-800
}
.ts-control > input{
   @apply text-sm h-5 text-gray-800 dark:text-gray-200 min-w-0
}
.ts-wrapper.single.input-active .ts-control {
   @apply bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400
}
.ts-wrapper.single.has-items .ts-control{
   @apply py-1.5 px-2 flex items-center flex-wrap
}
.ts-wrapper.multi.has-items .ts-control{
   @apply pt-1.5 pb-0.5 flex items-center flex-wrap
}
.ts-wrapper.multi .ts-control .item{
   @apply py-1 pl-2 pr-1.5 mr-1 mb-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded text-gray-500 dark:text-gray-400 text-sm max-h-[28px] max-w-[calc(100%-6px)] whitespace-nowrap
}
.ts-wrapper.multi .ts-control > div.active{
   @apply bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded
}
.ts-wrapper.single .ts-control .item{
   @apply py-1 px-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded text-gray-500 dark:text-gray-400 text-sm max-h-[28px] max-w-[calc(100%-6px)]
}
.ts-wrapper.ts-colors-select.single .ts-control .item{
   @apply px-1 border-none
}
.ts-wrapper.single .ts-control .item p{
   @apply truncate max-w-full
}
.ts-wrapper.multi .ts-control .item p{
   @apply truncate max-w-[calc(100%-20px)]
}
.ts-wrapper.companies-select {
   @apply whitespace-nowrap
}
.ts-wrapper.companies-select .ts-dropdown{
   @apply w-112 right-0 left-auto
}
.ts-wrapper.single.disabled .ts-control .item{
   @apply bg-transparent cursor-pointer !important
}
.ts-wrapper.single.disabled .ts-control *, .ts-wrapper.multi.disabled .ts-control *{
   @apply cursor-pointer !important
}
.is-invalid .ts-wrapper .ts-control{
   @apply ring-2 ring-inset ring-red-400 dark:ring-red-400
}
.is-invalid .ts-wrapper.focus .ts-control{
   @apply ring-2 ring-inset ring-primary dark:ring-primary-dark
}
.ts-control, .ts-wrapper.single.input-active .ts-control{
   @apply bg-white dark:bg-gray-800
}
.ts-dropdown{
   border-top: 1px solid #d1d5db;
   @apply border border-gray-300 dark:border-gray-600 rounded-lg shadow-light dark:shadow-dark dark:hover:shadow-dark-hover p-2.5 bg-white dark:bg-gray-800 z-100 overflow-hidden
}
.ts-dropdown.plugin-no_close {
   @apply relative border-0 shadow-none dark:hover:shadow-none py-2.5 px-0
}
.ts-wrapper.plugin-no_close .ts-control {
   @apply pl-8 min-h-10 !important
}
.ts-dropdown.plugin-no_close .ts-dropdown-content {
   @apply max-h-80
}
.ts-dropdown-content{
   @apply max-h-40
}
.ts-dropdown .option{
   @apply rounded-md px-2 py-2.5 text-sm font-medium text-gray-800 dark:text-gray-200
}
.ts-dropdown .option .option-link{
   @apply w-full max-w-[calc(100%-30px)]
}
.ts-dropdown .option p{
   @apply truncate max-w-[calc(100%-20px)]
}
.ts-dropdown .active, .ts-dropdown .selected{
   @apply bg-transparent
}
.ts-dropdown-content .active{
   @apply bg-gray-100 dark:bg-gray-700
}
.ts-dropdown .option:hover{
   @apply bg-gray-100 dark:bg-gray-700
}
.ts-dropdown .create, .ts-dropdown .no-results, .ts-dropdown .optgroup-header{
   @apply px-2 py-2.5 text-sm font-medium text-gray-800 dark:text-gray-200 dark:bg-gray-800
}
.ts-hidden-accessible {
   @apply h-10
}
.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove, .ts-wrapper.plugin-remove_button .item .remove{
   @apply border-l-0 ml-0 text-lg leading-5 -mt-0.5 font-normal
}
.ts-wrapper.plugin-remove_button .item .remove:hover{
   @apply bg-transparent text-gray-600
}
.ts-wrapper.plugin-remove_button.disabled .item .remove{
   @apply hidden
}
.ts-wrapper .item .item-max{
   @apply max-w-[260px]
}
.plugin-checkbox_options .option{
   @apply flex items-center
}
.is-facilities-select .plugin-checkbox_options .option{
   @apply flex items-start
}
.is-facilities-select .plugin-checkbox_options .option input[type="checkbox"]{
   @apply mt-0.5
}
.plugin-checkbox_options .option input{
   @apply mr-3
}
.plugin-checkbox_options .option input[type="checkbox"]{
   @apply w-4 h-4 bg-transparent border-gray-300 rounded focus:ring-2 focus:ring-primary-hover dark:focus:ring-primary-dark ring-offset-2 dark:ring-offset-gray-300 text-primary dark:text-primary-dark hover:border-primary-hover checked:border-primary dark:border-gray-600 dark:hover:border-primary-dark dark:checked:border-primary-dark disabled:border-gray-400 dark:disabled:border-gray-500
}
.plugin-checkbox_options .option:hover input[type="checkbox"]{
   @apply border-primary dark:border-primary-dark
}
.plugin-checkbox_options .option input[type="checkbox"]:checked{
   @apply bg-primary dark:bg-primary-dark
}
.ts-dropdown .spinner {
   @apply my-[3px]
}
.ts-control .item-flex, .ts-dropdown .option-flex{
   @apply flex items-center space-x-3
}
.ts-wrapper.single .ts-control > .item-flex + input{
   @apply hidden !important;
}
.disabled .ts-control .item a {
   cursor: pointer;
}
