.button-toggle.enabled{
   @apply bg-primary dark:bg-primary-dark
}
.button-toggle.enabled span{
   @apply translate-x-5
}
.button-toggle.disabled{
   @apply bg-gray-200 dark:bg-gray-700
}
.button-toggle.disabled span{
   @apply translate-x-0
}
