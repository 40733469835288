.grid-stack > .grid-stack-item {
  @apply cursor-pointer;
}
.grid-stack-static > .grid-stack-item {
  @apply cursor-auto;
}
.grid-stack > .grid-stack-item > .grid-stack-item-content {
  @apply overflow-visible;
}
.grid-stack .grid-stack-placeholder > .placeholder-content{
  @apply bg-gray-100 dark:bg-gray-700 rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-700
}
