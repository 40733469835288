.grid-stack .grid-stack-placeholder > .placeholder-content {
  @apply border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900
}
.grid-stack-item > .grid-stack-item-content {
  @apply cursor-grab
}
.grid-stack-item > .grid-stack-item-content .slideover{
  @apply cursor-default
}
.grid-stack-item.ui-draggable-dragging > .grid-stack-item-content {
  @apply shadow-none cursor-grabbing
}
.grid-stack-item.ui-draggable-dragging > .grid-stack-item-content .grid-stack-item-content-container {
  @apply shadow-light dark:shadow-dark dark:hover:shadow-dark-hover rounded-lg
}
.grid-stack-item-content-container .disabled {
  @apply disabled:bg-gray-100
}
