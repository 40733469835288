.slideover {
  @apply h-full bg-white inset-0 dark:bg-gray-800 sm:inset-y-0 sm:right-0 sm:left-auto overflow-hidden
}
.slideover-xs {
  @apply w-full lg:w-128 /* 512px */;
}
.slideover-sm {
  @apply w-full lg:w-160 /* 640px */;
}
.slideover-md {
  @apply w-full lg:w-184 /* 736px */;
}
.slideover-lg {
  @apply w-full lg:w-208 /* 832px */;
}
.slideover-xl {
  @apply w-full lg:w-244 /* 992px */;
}
.slideover-2xl {
  @apply w-full lg:w-320 /* 1280px */;
}
.slideover-4xl {
  @apply w-full 2xl:w-400 /* 1536px */;
}

.slideover-xs .slideover-footer > *:not(:only-child) {
  @apply flex-1
}

dialog {
  max-width: 100vw;
  max-height: 100vh;
}
html:has(dialog[open]) {
  overflow: hidden;
}
dialog.mobile-menu[open] {
  animation: slide-in-from-left 250ms forwards ease;
}
dialog.mobile-menu[closing] {
  pointer-events: none;
  animation: slide-out-to-left 250ms forwards ease-in-out;
}
dialog.slideover[open] {
  animation: slide-in-from-right 250ms forwards ease;
}
dialog.slideover[closing] {
  pointer-events: none;
  animation: slide-out-to-right 250ms forwards ease-in-out;
}


@keyframes slide-in-from-right{
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0%)
  }
}

@keyframes slide-out-to-right{
  to {
    transform: translateX(100%)
  }
}

@keyframes slide-in-from-left{
  from {
    transform: translateX(-100%)
  }
}

@keyframes slide-out-to-left{
  to {
    transform: translateX(-100%)
  }
} 
