.table-tabs-active{
   @apply text-gray-900 bg-white
}
.table-tabs-active .icon{
   @apply text-primary-hover 
}
.table-tabs-active .counter{
   @apply bg-violet-100 text-primary dark:text-primary-dark group-hover:text-primary-hover
}
.table-tabs-active .bg{
   @apply bg-white
}
.table-tabs-active .line{
   @apply bg-primary-hover 
}
.table-tabs-inactive{
   @apply text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-900
}
.table-tabs-inactive .icon{
   @apply text-gray-400
}
.table-tabs-inactive .counter{
   @apply bg-gray-200 text-gray-500 dark:text-gray-400 group-hover:text-gray-600
}
.table-tabs-inactive .bg{
   @apply bg-gray-50 dark:bg-gray-900
}
.table-tabs-inactive .line{
   @apply bg-transparent
}
