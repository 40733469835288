.is-invalid input:not([class="tomselect-checkbox"]), .is-invalid button, .is-invalid .checkbox-wrapper, .is-invalid textarea, .contenteditable.is-invalid{
  @apply ring-2 ring-red-400 dark:ring-red-400 focus:ring-red-400 dark:focus:ring-red-400 focus-visible:ring-red-400 dark:focus-visible:ring-red-400
}
.was-invalid input:not([type="checkbox"],[type="radio"]), .was-invalid button, .was-invalid .checkbox-wrapper, .was-invalid textarea{
  @apply ring-1 ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary dark:focus:ring-primary-dark focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-primary-dark
}
.was-invalid .error-message{
  @apply hidden
}
.business-process-status .error-message{
  @apply mt-0 mb-1 mx-3
}

.workflow_delay_in_working_days{
  @apply space-y-0 items-center
}

/* checkbox */
input[type="checkbox"].checkbox{
   @apply w-4 h-4 bg-transparent border-gray-300 rounded focus:ring-2 focus:ring-primary-hover dark:focus:ring-primary-dark ring-offset-2 ring-offset-white dark:ring-offset-gray-800 text-primary-hover dark:text-primary-dark hover:border-primary-hover checked:border-primary-hover checked:bg-primary dark:checked:bg-primary-dark dark:border-gray-600 dark:hover:border-primary-dark dark:checked:border-primary-dark disabled:bg-gray-100 dark:disabled:bg-gray-700 disabled:border-gray-200 dark:disabled:border-gray-600 checked:disabled:bg-primary/50 dark:checked:bg-primary-dark/50 checked:disabled:border-primary-hover/50
}

/* radio */
input[type="radio"].checkbox, input[type="radio"].radio{
   @apply w-4 h-4 bg-transparent border-gray-300 rounded-full focus:ring-2 focus:ring-primary-hover dark:focus:ring-primary-dark ring-offset-2 ring-offset-white dark:ring-offset-gray-800 text-primary-hover dark:text-primary-dark hover:border-primary-hover checked:border-primary-hover checked:bg-primary dark:checked:bg-primary-dark dark:border-gray-600 dark:hover:border-primary-dark dark:checked:border-primary-dark disabled:bg-gray-200 dark:disabled:bg-gray-700 disabled:border-gray-300 dark:disabled:border-gray-600
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
