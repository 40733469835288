.radio-card input:checked + label {
  @apply border-primary dark:border-primary-dark;
}

.radio-card input:checked + label span {
  @apply block;
}

.radio-card input:checked + label svg{
  @apply text-primary dark:text-primary-dark;
}

.radio-card.radio-card-alt input:checked + label {
  @apply border-primary dark:border-primary-dark bg-primary dark:bg-primary-dark text-white;
}

.radio-block input:not(:checked) + div select,
.radio-block input:not(:checked) + div .radio-card label {
  @apply border-gray-200 dark:border-gray-700 text-gray-200;
}

.radio-block input:not(:checked) + div .radio-card.radio-card-alt input:checked + label {
  @apply border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-200;
}

.radio-card-width input:checked + span.collection_radio_buttons {
  @apply bg-white dark:bg-gray-800;
}

.radio-card-width input:checked + span.collection_radio_buttons {
  @apply bg-white dark:bg-gray-800;
}

.radio-card-icon input + label {
  @apply mx-auto;
}

.radio-card-icon input:checked + label svg {
  @apply text-white;
}
